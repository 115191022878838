const theme = {
  PrimaryColor: '#2f3837',
  Red: '#dc3545',
  Blue: '#007bff',
  Green: '#2A721D',
  Orange: '#f29418',
  Purple: '#8c14fc ',
  TimedSale: '#3391b5',
  Note: '#6c757d',
  AlertColours: {
    Note: '#6c757d',
    AgentNote: '#007bff',
    CodeRed: '#dc3545'
  },
  AgentQueryHotelColors: {
    NormalBackGround: '#b4d6fa',
    NormalText: '#007bff'
  },
  HotelColors: {
    Blue: '#05668d',
    Green: '#006400',
    Brown: '#a86020',
    Yellow: '#ffb703',
    Red: '#6a040f',
    Grey: '#666666',
    Black: '#000000'
  },
  EmailColours: {
    Note: '#6c757d',
    Basic: '#6c757d',
    Normal: '#6c757d',
    Today: '#f29418',
    Tomorrow: '#8c14fc ',
    Upcoming: '#007bff',
    AgentNote: '#007bff',
    Priority: '#f29418',
    Urgent: '#dc3545',
    Open: '#2A721D',
    Status: {
      Open: '#1CA015',
      Waiting: '#DBA800',
      Closed: '#dc3545',
      Basic: '#6c757d'
    }
  },
  EmailBackgroundColours: {
    Basic: '#F8F8F8',
    Note: '#F8F8F8',
    Today: '#fbdeb9',
    Upcoming: '#b2d7ff',
    Urgent: '#f4c2c7',
    Open: '#F8F8F8',
    Waiting: '#FFDD6D',
    Holding: '#f7ae6a',
    WaitingForPayment: '#f7ae6a',
    Closed: '#f4c2c7',
    Selected: '#e1dcdc',
    NewReply: '#9AEF83',
    Alt: '#e1dcdc',
    OverlappingDates: 'rgb(140 20 252 / 50%)'
  },
  ReportingRequirement: {
    Mandatory: '#FE4949',
    Required: '#FFA07A'
  },
  CompanyCrmBackgroundColours: {
    Basic: '#F8F8F8',
    LongTerm: '#FFDBE2',
    SmallGroup: '#F1EB9C',
    LargeGroup: '#A3FFA3'
  },
  MirStatusColors: {
    Imported: '#46001e',
    ValidationIssue: '#ffb703',
    FLSQNotFound: '#E53B1A',
    Validated: '#6735ca',
    ParsedMissingMir: '#007bff',
    Parsed: '#8c14fc',
    Exported: '#35ca7e',
    GroupExported: '#3cca35',
    ImportParsingError: '#ffb703'
  }
};

export type ITheme = typeof theme;
export default theme;
